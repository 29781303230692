import { useState, useEffect } from 'react'
import { Navbar, Container } from 'react-bootstrap'
import { ipLocationUrl } from '../constant'
import axios from 'axios'
import Styles from './StatusBar.module.css'

const StatusBar = () => {
  const [ipAddress, setIpAddress] = useState('')
  const [location, setLocation] = useState('')

  useEffect(() => {
    const getIpLocation = async () => {
      try {
        const response = await axios.get(ipLocationUrl)
        const { data } = response
        setIpAddress(data.ip)
        setLocation(`${data.city}, ${data.country_name}`)
      } catch (error) {
        console.error(error)
      }
    }

    getIpLocation()
  }, [])

  return (
    <>
      <Navbar className={Styles.navbar}>
        <Container className="d-flex justify-content-center align-item-center">
          <Navbar.Text className={Styles.navbar_text_style}>
            Your IP : {ipAddress}
          </Navbar.Text>
          <Navbar.Text className={Styles.navbar_text_style}>
            Your Location : {location}
          </Navbar.Text>
          <span className='p-2'>
            <a href="https://instagram.com" target="_blank" rel="noreferrer">
              <i class="fa-brands fa-telegram fa-lg"></i>
            </a>
          </span>
          <span className='p-2'>
            <a href="https://instagram.com" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-whatsapp fa-lg"></i>
            </a>
          </span>
        </Container>
      </Navbar>
    </>
  )
}

export default StatusBar
