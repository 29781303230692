import Styles from './PrivacyScreen.module.css'
import { Image } from 'react-bootstrap'
import { aboutusBanner } from '../constant'
import Header from '../components/Header'
import Footer from '../components/Footer'

const AboutUsScreen = () => {
  return (
    <>
      <Header />

      <div className={Styles.imageContainer}>
        <Image
          src={aboutusBanner}
          alt="About Us Banner"
          fluid
        />
      </div>
      {/* <div className={Styles.overlay}>
        <p className={Styles.text}>About Us</p>
      </div> */}

      <div className={Styles.about_us_container}>
        <h2 className={Styles.h2}>Privacy Policy</h2>

        <div className={Styles.about_us_content}>
          <h3 className={Styles.h3}>Overview</h3>
          <p className={Styles.p}>
            Our underlying policy is that we want you to remain anonymous when
            using our service. It is therefore our policy to never store any
            activity logs or metadata and to have as minimal data retention as
            possible.
          </p>

          <h3 className={Styles.h3}>Security</h3>
          <p className={Styles.p}>
            We have implemented best-in-class physical, procedural, and
            technical security measures with respect to our offices and
            information storage facilities so as to prevent any loss, misuse, or
            unauthorized access, disclosure, or modification of your Personal
            Data. Although we believe these systems are robust, it is important
            to understand that no data security measures in the world can offer
            completely infallible protection.
          </p>

          <h3 className={Styles.h3}>Encryption</h3>
          <p className={Styles.p}>
            We use the latest encryption technology to protect your online
            activities. All data transmitted through our VPN
            service is encrypted, ensuring that your online activities remain
            private and secure.
          </p>

          <h3 className={Styles.h3}>Servers and data centers</h3>
          <p className={Styles.p}>
            Servers are housed in data centers with strong security practices.
            None of these data centers require us to collect or store any
            traffic data or Personal Data related to your use of the Services.
            If any data center were to ask us to log such data, we would
            immediately cease operations with said service provider and find
            alternative options. Even if a government were to physically seize
            one of our VPN servers, there would be no logs or information that
            would tie any individual user to a particular event, website, or
            behavior.
          </p>

          <h3 className={Styles.h3}>Retention of your Personal Data</h3>
          <p className={Styles.p}>
            Your Personal Data—which, to reiterate, never includes any sensitive
            data such as browsing history, DNS queries, or IP addresses linked
            to that information or any other online behavior—is retained for a
            limited period in accordance with applicable data protection law
            (for as long as we have your consent or a legitimate reason for
            holding such data).
          </p>

          <h3 className={Styles.h3}>Pay to Use Service</h3>
          <p className={Styles.p}>
            Our VPN service is a pay-to-use service. We do not offer any free
            services, as we believe that free services often come at the cost of
            your privacy. By paying for our service, you can be assured that we
            are committed to protecting your privacy and providing you with the
            best possible service.
          </p>

          <h3 className={Styles.h3}>Updates</h3>
          <p className={Styles.p}>
            This Privacy policy may be updated and, in such case, a new version
            will be published on VPNPremium's website.
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutUsScreen
