import React from 'react'
import { Row, Col, Container, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Divider from './Divider'
import Styles from './Footer.module.css'

const Footer = () => {
  return (
    <footer className={Styles.footer}>
      <div className={Styles.div}>
        <Container>
          <Row>
            <Col className={Styles.col} md={6}>
              <h4 className={Styles.h4}>| Address</h4>
              <h6 className={Styles.h6}>
                <i className="fas fa-map-marker-alt me-2"></i>
                27 Division St, New York, NY 1002 United States of America
              </h6>
              <h6 className={Styles.h6}>
                <i className="fas fa-envelope me-2"></i>
                vpnpremium.net@gmail.com
              </h6>
              <h6 className={Styles.h6}>
                <i className="fas fa-phone-alt me-2"></i>
                +1 123-456-7890
              </h6>
              <Row>
                <div className={Styles.icon_div}>
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa-brands fa-telegram fa-lg"></i>
                  </a>
                </div>
                <div className={Styles.icon_div}>
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-whatsapp fa-lg"></i>
                  </a>
                </div>
              </Row>
            </Col>
            <Col className={Styles.col} md={4}>
              <h4 className={Styles.h4}>| Services</h4>
              <h6 className={Styles.h6}>Premium Services</h6>
              <h6 className={Styles.h6}>No Traffic Logs</h6>
              <h6 className={Styles.h6}>Instant Setups</h6>
            </Col>
            <Col className={Styles.col} md={2}>
              <h4 className={Styles.h4}>| Other Pages</h4>
              <LinkContainer to="/about-us">
                <Nav.Link>
                  <h6 className={Styles.h6}> About Us </h6>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact-us">
                <Nav.Link>
                  <h6 className={Styles.h6}> Contact Us </h6>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/privacy">
                <Nav.Link>
                  <h6 className={Styles.h6}> Privacy Policy </h6>
                </Nav.Link>
              </LinkContainer>
            </Col>
          </Row>
          <div className={Styles.last_div}>
            <Divider />
            <br />
            <Col className={Styles.col_last}>
              Copyright &copy; 2023 <strong>VPNPREMIUM.net</strong> . All Rights
              Reserved.
            </Col>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
