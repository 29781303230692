import axios from 'axios'
import {
  PACKAGE_LIST_REQUEST,
  PACKAGE_LIST_SUCCESS,
  PACKAGE_LIST_FAIL,
} from '../constants/packageConstants'
import { packageUrl } from '../constant'

export const listPackages = () => async (dispatch) => {
  try {
    dispatch({ type: PACKAGE_LIST_REQUEST })
    const response = await axios.get(packageUrl)
    console.log(response.data.packages)
    dispatch({
      type: PACKAGE_LIST_SUCCESS,
      payload: response.data.packages,
    })
  } catch (error) {
    dispatch({
      type: PACKAGE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
