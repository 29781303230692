import axios from 'axios'
import {
  forgotPasswordUrl,
  loginUrl,
  profileUpdateUrl,
  profileUrl,
  signupURL,
} from '../constant'
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
  USER_PASSWORD_RECOVER_REQUEST,
  USER_PASSWORD_RECOVER_SUCCESS,
  USER_PASSWORD_RECOVER_FAIL,
  USER_PASSWORD_RECOVER_STATE_RESET,
  USER_PROFILE_RESET,
} from '../constants/userConstants'
import { ORDER_LIST_RESET } from '../constants/orderConstants'

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const response = await axios.post(loginUrl, { email, password }, config)
    console.log(response.data)

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: response.data,
    })
    localStorage.setItem('userInfo', JSON.stringify(response.data))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo')
  dispatch({ type: USER_LOGOUT })
  dispatch({ type: USER_PROFILE_RESET })
  dispatch({ type: ORDER_LIST_RESET })
}

export const register = (name, email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const response = await axios.post(
      signupURL,
      { name, email, password },
      config
    )
    console.log(response.data)

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: response.data,
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: response.data,
    })

    localStorage.setItem('userInfo', JSON.stringify(response.data))
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const profile = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_PROFILE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const response = await axios.get(`${profileUrl}/${id}`, config)
    console.log(response.data)

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_PROFILE_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const response = await axios.put(profileUpdateUrl, user, config)
    console.log(response.data)

    dispatch({
      type: USER_PROFILE_UPDATE_SUCCESS,
      payload: response.data,
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: response.data,
    })

    localStorage.setItem('userInfo', JSON.stringify(response.data))
  } catch (error) {
    dispatch({
      type: USER_PROFILE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: USER_PASSWORD_RECOVER_REQUEST })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const response = await axios.post(forgotPasswordUrl, { email }, config)
    console.log(response.data)

    dispatch({
      type: USER_PASSWORD_RECOVER_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_RECOVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const forgotPasswordStateReset = () => (dispatch) => {
  dispatch({ type: USER_PASSWORD_RECOVER_STATE_RESET })
}
