import { useEffect } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Divider from './Divider'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listPackages } from '../actions/packageActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import BeautyLine from './BeautyLine'
import Styles from './Packages.module.css'

const Packages = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(listPackages())
  }, [dispatch])

  const { packages, loading, error } = useSelector((state) => state.packageList)

  const handleChoosePackage = (pkg) => {
    navigate(`/checkout/${pkg._id}`, { state: { package: pkg } })
  }

  return (
    <div className={Styles.div}>
      <Container>
        <h2 className={Styles.h2}>Pricing Plan</h2>
        <br></br>
        <BeautyLine />
        {loading ? (
          <>
            <br />
            <br />
            <Loader />
          </>
        ) : error ? (
          <Message varient="danger">{error}</Message>
        ) : (
          <Row>
            {packages.map((pkg) => (
              <Col key={pkg.name} className={Styles.col}>
                <Card className={Styles.card}>
                  {pkg.recommended && (
                    <div className={Styles.recommended_banner}>Featured</div>
                  )}
                  <Card.Body>
                    <Card.Title className={Styles.card_title}>
                      {pkg.name}
                    </Card.Title>
                    <Card.Text>{pkg.description}</Card.Text>
                    <h3 className={Styles.card_price}>
                      {pkg.price}{' '}
                      <i class="fa-sharp fa-solid fa-dollar-sign fa-2xs"></i>
                    </h3>
                    <Card.Text className={Styles.card_month}>
                      {pkg.name === 'Basic'
                        ? '1 Month'
                        : pkg.name === 'Advanced'
                        ? '3 Month'
                        : pkg.name === 'Ultimate'
                        ? '6 Month'
                        : ''}
                    </Card.Text>
                    <Divider />
                    <br></br>

                    <ul className={Styles.card_features}>
                      {pkg.features.map((feature) => (
                        <div>
                          <li key={feature}>&#10004; {feature}</li>
                          <div className="p-1"></div>
                        </div>
                      ))}
                    </ul>
                    <Button
                      variant="primary"
                      onClick={() => handleChoosePackage(pkg)}
                    >
                      Choose
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  )
}

export default Packages
