import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col, Image, FloatingLabel } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { register } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import { circleLogoUrl, signupImageUrl } from '../constant'
import Styles from './SignupScreen.module.css'

const RegisterScreen = ({ location }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading, error, userInfo } = useSelector(
    (state) => state.userRegister
  )

  const redirect = location?.search ? location.search.split('=')[1] : '/'

  const handleRedirectHome = () => {
    navigate(redirect)
  }

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [navigate, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else if (!/(?=.*[A-Z])/.test(password)) {
      setMessage('Password must contain at least one uppercase letter')
    } else if (!/(?=.*\d)/.test(password)) {
      setMessage('Password must contain at least one numeric character')
    } else if (password.length < 8) {
      setMessage('Password must be at least 8 characters long')
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setMessage('Invalid email address')
    } else {
      dispatch(register(name, email, password))
    }
  }

  return (
    <div style={{ width: '99%' }}>
      <Row lg={2} md={1} xs={1} sm={1}>
        <Col className={Styles.col}>
          <i
            style={{
              position: 'absolute',
              top: '4rem',
              left: '3rem',
              color: 'white',
            }}
            onClick={handleRedirectHome}
            class="fa-solid fa-house fa-2xl"
          ></i>
          <Image
            src={signupImageUrl}
            style={{ width: '80%', height: 'auto' }}
          ></Image>
        </Col>
        <Col className="d-flex justify-content-center align-items-center">
          <FormContainer>
            <Image
              className="pt-5 m-auto d-flex justify-content-center align-items-center"
              src={circleLogoUrl}
              style={{ width: '25%' }}
            ></Image>
            <h1
              style={{
                fontFamily: 'Nunito Sans',
                marginTop: '6rem',
                marginBottom: '2rem',
              }}
              className="d-flex justify-content-center align-items-center"
            >
              Register an Account
            </h1>
            {message && <Message varient="danger">{message}</Message>}
            {error && <Message varient="danger">{error}</Message>}
            {loading && <Loader />}
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="name">
                <FloatingLabel controlId="name" label="Name" className="mb-4">
                  <Form.Control
                    type="name"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="email">
                <FloatingLabel
                  controlId="email"
                  label="Email Address"
                  className="mb-4"
                >
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      setMessage(null)
                    }}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="password">
                <FloatingLabel
                  controlId="password"
                  label="Password"
                  className="mb-4"
                >
                  <Form.Control
                    type="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                      setMessage(null)
                    }}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <FloatingLabel
                  controlId="password"
                  label="Confirm Password"
                  className="mb-4"
                >
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                      setMessage(null)
                    }}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>

              <Button
                type="submit"
                size="lg"
                variant="primary"
                style={{
                  width: '100%',
                  height: '3.5rem',
                  marginBottom: '5rem',
                }}
              >
                Create
              </Button>
            </Form>

            <Row>
              <Col
                style={{ fontFamily: 'Nunito Sans', fontWeight: 'bold' }}
                className="d-flex justify-content-center"
              >
                <span className="pb-5" style={{ marginRight: '1rem' }}>
                  Already Have An Account?
                </span>
                <Link
                  style={{ color: '#0d6efd', textDecoration: 'none' }}
                  to={redirect ? `/login?redirect=${redirect}` : '/login'}
                >
                  Login
                </Link>
              </Col>
            </Row>
          </FormContainer>
        </Col>
      </Row>
    </div>
  )
}

export default RegisterScreen
