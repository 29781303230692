import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';

const CounterCore = ({ end }) => {
  const counterRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  return (
    <div ref={counterRef}>
      {isVisible && (
        <h1 style={{ fontSize: '48px', fontFamily: 'Nunito Sans', fontWeight: '900', color: 'white', padding: '1.25rem 0rem', margin: 'auto' }}>
          <CountUp end={end} duration={2} separator="," />
        </h1>
      )}
    </div>
  );
};

export default CounterCore;