import React from 'react'
import { Image, Row, Col, Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Styles from './ShieldBanner.module.css'

const ShieldBanner = () => {
  const navigate = useNavigate()
  const handleButtonClick = () => {
    navigate('/pricing')
  }
  return (
    <Container fluid
      style={{
        background: 'linear-gradient(to right, #00bae5, #01084c)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>

      <Row className="justify-content-center align-items-center">
        <Col
          style={{ color: '#ffffff', padding: '9rem 0rem' }}
        >
          <h4 className="shield">VPN Protection</h4>
          <h1 className="shield">
            Your Life is Under<br></br> Our Security
          </h1>
          <p className="shield">
            <span>
            Improve privacy and security of your business to protect sensitive information and prevent unauthorized access.
            </span>
          </p>
          <Button onClick={handleButtonClick} className={Styles.banner_btn}>GET IT NOW</Button>
        </Col>
        <Col lg={6}>
          <Image
            src={process.env.PUBLIC_URL + '/images/laptop-shield.jpg'}
            alt="Shielded Laptop Image"
            fluid
          />
        </Col>
      </Row>
      </div>
    </Container>
  )
}

export default ShieldBanner
