import React, { useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Container, Button, Alert, Row, Image, Col } from 'react-bootstrap'
import Header from './Header'
import Footer from './Footer'
import { getLoggedInUrl, sessionUrl, terminateUrl, xAppId } from '../constant'

const ActiveSession = () => {
  const [show, setShow] = useState(false)
  const { userInfo } = useSelector((state) => state.userLogin)
  const [loggedInValue, setLoggedInValue] = useState()
  const [data, setData] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchLoggedInValue = async () => {
      try {
        if (!userInfo) {
          navigate('/')
          return
        }
        const { data } = await axios.post(
          getLoggedInUrl,
          {
            userId: userInfo._id,
          },
          {
            headers: {
              'x-app-id': xAppId,
            },
          }
        )
        setLoggedInValue(data.isLoggedIn)
      } catch (error) {
        console.log(error)
      }
    }
    fetchLoggedInValue()
  }, [userInfo, navigate])

  const terminateActiveDevice = async () => {
    try {
      const { data } = await axios.post(
        terminateUrl,
        {
          email: userInfo.email,
        },
        {
          headers: {
            command: 'logout',
          },
        }
      )
      setData(data)
      setShow(true)
      setLoggedInValue(data.isLoggedIn)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {data && show && (
        <Alert show={show} variant="success">
          <Alert.Heading>{data.message}</Alert.Heading>
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShow(false)} variant="outline-success">
              Close
            </Button>
          </div>
        </Alert>
      )}
      <Header />
      <Container>
        <Row style={{ padding: '8rem 2rem' }}>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image src={sessionUrl} />
          </Col>
          <Col>
            <h1
              style={{
                fontFamily: 'Rubik',
                textAlign: 'center',
                padding: '1rem',
              }}
            >
              Current Session
            </h1>
            {loggedInValue ? (
              <Row style={{ padding: '2rem' }}>
                <Col>
                  <h3 style={{ fontFamily: 'Rubik', textAlign: 'center' }}>
                    1 active device
                  </h3>
                </Col>
                <Col>
                  <h3 style={{ fontFamily: 'Rubik', textAlign: 'center', color: '#00FF00' }}>
                    Online
                  </h3>
                </Col>
              </Row>
            ) : (
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h3 style={{marginTop: '2rem'}}>No active session</h3>
              </Col>
            )}
            <Col>
              {loggedInValue && (
                <Button
                  onClick={terminateActiveDevice}
                  variant="danger"
                  style={{
                    fontFamily: 'Rubik',
                    width: '100%',
                    height: '3.5rem',
                    marginTop: '1rem',
                  }}
                >
                  Terminate Active Session
                </Button>
              )}
            </Col>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default ActiveSession
