import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Col, Row, Button, Tabs, Tab, Form } from 'react-bootstrap'
import Styles from './CheckoutScreen.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { proceedPayment } from '../actions/paymentAction'
import Message from '../components/Message'
import Loader from '../components/Loader'
import axios from 'axios'
import Divider from '../components/Divider'
import { nowPaymentsApiKey, pricingBgUrl } from '../constant'
import BeautyLine from '../components/BeautyLine'

const CheckoutScreen = () => {
  const [proceedClicked, setProceedClicked] = useState(false)

  const location = useLocation()
  const selectedPackage = location.state.package
  const [currencies, setCurrencies] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const [estimatedPricesInfo, setEstimatedPricesInfo] = useState({})

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userInfo } = useSelector((state) => state.userLogin)
  const { loading, payInfo, error } = useSelector(
    (state) => state.paymentRequest
  )

  const redirect = location?.search ? location.search.split('=')[1] : '/login'

  useEffect(() => {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://api.nowpayments.io/v1/merchant/coins',
      headers: {
        'x-api-key': nowPaymentsApiKey,
      },
    }
    const fetchCurrencies = async () => {
      try {
        const response = await axios(config)
        setCurrencies(response.data.selectedCurrencies)
      } catch (error) {
        console.log(error)
      }
    }
    fetchCurrencies()
  }, [])

  useEffect(() => {
    if (proceedClicked && payInfo.invoiceUrl) {
      window.open(payInfo.invoiceUrl, '_blank')
    }
  }, [payInfo, proceedClicked])

  const handleProceed = async () => {
    if (!userInfo) {
      navigate(redirect)
    } else {
      try {
        setProceedClicked(true)
        //dispatch(resetPayInfo())
        await dispatch(proceedPayment(selectedPackage, selectedCurrency))
      } catch (error) {
        console.log(error)
      }
    }
  }

  const calculateEndDate = () => {
    const currentDate = new Date()

    if (selectedPackage.name === 'Basic') {
      const endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate()
      )
      return endDate.toDateString()
    }

    if (selectedPackage.name === 'Advanced') {
      const endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 3,
        currentDate.getDate()
      )
      return endDate.toDateString()
    }

    if (selectedPackage.name === 'Ultimate') {
      const endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 6,
        currentDate.getDate()
      )
      return endDate.toDateString()
    }

    return '' // Default value if no package is selected
  }

  useEffect(() => {
    console.log(`Selected currency: ${selectedCurrency}`)
  }, [selectedCurrency])

  const handleSelectedCurrency = (currency) => {
    setSelectedCurrency(currency)
  }

  const handleNextTab = () => {
    setActiveTab(1)
  }

  const handleRedirectHome = () => {
    navigate('/')
  }

  const estimatePrices = async () => {
    const currencies = ['btc', 'eth', 'usdt', 'xrp', 'trx']
    const estimatedPricesData = {}

    for (let currency of currencies) {
      try {
        const response = await axios.get(
          `https://api.nowpayments.io/v1/estimate?amount=${selectedPackage.price}&currency_from=usd&currency_to=${currency}`,
          { headers: { 'x-api-key': nowPaymentsApiKey } }
        )
        if (response.status === 200) {
          estimatedPricesData[currency] = response.data.estimated_amount
        }
      } catch (error) {
        console.log(error)
      }
    }

    setEstimatedPricesInfo(estimatedPricesData)
    console.log('estimated prices', estimatedPricesInfo)
  }

  estimatePrices()

  return (
    <>
      <Tabs
        justify
        id="checkout-tabs"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
      >
        <Tab eventKey={0} title="Checkout">
          <div
            style={{
              width: '100%',
              height: '100vh',
              paddingTop: '5rem',
              backgroundColor: 'white',
              backgroundImage: `url(${pricingBgUrl})`,
            }}
          >
            <Container>
              <h1 className={Styles.h1}>
                Plan {selectedPackage.name} /{' '}
                {selectedPackage.name === 'Basic'
                  ? '1 Month'
                  : selectedPackage.name === 'Advanced'
                  ? '3 Month'
                  : selectedPackage.name === 'Ultimate'
                  ? '6 Month'
                  : ''}
              </h1>
            </Container>

            <i
              style={{
                position: 'absolute',
                top: '4rem',
                left: '3rem',
              }}
              onClick={handleRedirectHome}
              class="fa-solid fa-house fa-2xl"
            ></i>

            <Container>
              <Row>
                <Col
                  md={7}
                  style={{ backgroundColor: 'white', borderRadius: '2rem' }}
                  className="p-5"
                >
                  <Row>
                    <h4 className={Styles.h4}>What You Will Get</h4>
                    <span className="p-3" style={{ fontFamily: 'Rubik' }}>
                      <i class="fa-solid fa-calendar-check fa-lg"></i> By
                      purchasing this package, you will have access to our
                      services until <strong>{calculateEndDate()}</strong>
                    </span>
                    <Divider />
                    <h4 style={{ paddingTop: '1.5rem' }} className={Styles.h4}>
                      Payment method
                    </h4>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <Form.Check
                       style={{fontFamily: 'Rubik'}}
                        className="p-4"
                        type="radio"
                        id="nowpaymentsRadio"
                        label="NowPayments"
                        defaultChecked
                      />
                      <img
                        src={process.env.PUBLIC_URL + '/images/nowpayments.jpg'}
                        alt="nowpayments"
                        style={{
                          width: '10rem',
                          height: '3rem',
                          borderRadius: '0.25rem',
                        }}
                      />
                    </span>
                    <span className="p-4" style={{fontFamily: 'Rubik'}}>
                      NOTE: We only accept payment with crypto currency for now
                    </span>
                    <Divider />
                    <span className="p-4"  style={{fontFamily: 'Rubik'}}>
                      To select your desired digital currency, click on "next"
                    </span>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col
                  md={4}
                  className="p-2"
                  style={{
                    backgroundColor: '#222222',
                    borderRadius: '2rem',
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/Lines.png)`,
                    overflow: 'hidden',
                  }}
                >
                  <div style={{ width: '100%', height: '100%' }}>
                    <h4
                      style={{
                        textAlign: 'center',
                        color: 'white',
                        marginTop: '2rem',
                      }}
                    >
                      Features
                    </h4>
                    <ul className={Styles.features_list}>
                      {selectedPackage.features.map((feature) => (
                        <li key={feature} className={Styles.div_li}>
                          <span style={{ marginRight: '0.5rem' }}>
                            &#10004;
                          </span>
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        size="lg"
                        style={{ width: '15rem' }}
                        variant="primary"
                        onClick={handleNextTab}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="mb-5">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '3rem 0rem',
                  }}
                >
                  <h2 className={Styles.h2}>Estimated Prices</h2>
                  <h5 className={Styles.h5}>
                    The digital currencies that our users mostly use for
                    payments
                  </h5>
                </div>
                <BeautyLine />
                <div className="mt-4"></div>

                <Col
                  xs={12}
                  className={Styles.col_estimate}
                  style={{
                    backgroundColor: '#e5def0',
                  }}
                >
                  <div className="mt-5">
                    <h5>{estimatedPricesInfo.btc} BTC</h5>
                    <p>{selectedPackage.price} $</p>
                    <div className={Styles.div_estimate}>
                      <img
                        src={
                          process.env.PUBLIC_URL + '/images/coins/bitcoin.svg'
                        }
                        alt="Bitcoin"
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  className={Styles.col_estimate}
                  style={{
                    backgroundColor: '#d6edd9',
                  }}
                >
                  <div className="mt-5">
                    <h5>{estimatedPricesInfo.eth} ETH</h5>
                    <p>{selectedPackage.price} $</p>
                    <div className={Styles.div_estimate}>
                      <img
                        src={
                          process.env.PUBLIC_URL + '/images/coins/ethereum.svg'
                        }
                        alt="Ethereum"
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  className={Styles.col_estimate}
                  style={{
                    backgroundColor: '#f6f0d8',
                  }}
                >
                  <div className="mt-5">
                    <h5>{estimatedPricesInfo.usdt} USDT</h5>
                    <p>{selectedPackage.price} $</p>
                    <div className={Styles.div_estimate}>
                      <img
                        src={
                          process.env.PUBLIC_URL + '/images/coins/tether.svg'
                        }
                        alt="USDT"
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  className={Styles.col_estimate}
                  style={{
                    backgroundColor: '#aad2dd',
                  }}
                >
                  <div className="mt-5">
                    <h5>{estimatedPricesInfo.xrp} XRP</h5>
                    <p>{selectedPackage.price} $</p>
                    <div className={Styles.div_estimate}>
                      <img
                        src={process.env.PUBLIC_URL + '/images/coins/xrp.svg'}
                        alt="XRP"
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  className={Styles.col_estimate}
                  style={{
                    backgroundColor: '#ddaab2',
                  }}
                >
                  <div className="mt-5">
                    <h5>{estimatedPricesInfo.trx} TRX</h5>
                    <p>{selectedPackage.price} $</p>
                    <div className={Styles.div_estimate}>
                      <img
                        src={process.env.PUBLIC_URL + '/images/coins/trx.svg'}
                        alt="TRX"
                      />
                    </div>
                  </div>
                </Col>
                <div style={{ height: '5rem' }}></div>
              </Row>
            </Container>
          </div>
        </Tab>
        <Tab eventKey={1} title="Currency">
          <div style={{ backgroundImage: `url(${pricingBgUrl})` }}>
            <Container>
              <i
                style={{
                  position: 'absolute',
                  top: '4rem',
                  left: '3rem',
                }}
                onClick={handleRedirectHome}
                class="fa-solid fa-house fa-2xl"
              ></i>
              <div className={Styles.div}>
                <h2>Select Currency</h2>
                {error && <Message variant="danger">{error}</Message>}
                {loading && <Loader />}
                {!loading && !error && (
                  <Row lg={3}>
                    {currencies.map((currency) => (
                      <Col key={currency}>
                        <div
                          className={`${Styles.currencyItemWrapper} ${
                            selectedCurrency === currency ? Styles.selected : ''
                          }`}
                          onClick={() => handleSelectedCurrency(currency)}
                        >
                          <img
                            src={`https://nowpayments.io/images/coins/${currency.toLowerCase()}.svg`}
                            alt={currency}
                            style={{
                              width: '50px',
                              height: '50px',
                              marginRight: '0.5rem',
                            }}
                          />
                          <div>{currency}</div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
                <Button
                  size="lg"
                  style={{
                    width: '18rem',
                    height: '3rem',
                    marginTop: '2rem',
                  }}
                  variant="primary"
                  disabled={!selectedCurrency}
                  onClick={() => handleProceed()}
                >
                  Proceed
                </Button>
              </div>
            </Container>
          </div>
        </Tab>
      </Tabs>
    </>
  )
}

export default CheckoutScreen
