import axios from 'axios'
import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
} from '../constants/orderConstants'
import { orderUrl, xAppId } from '../constant'

export const listOrders = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_LIST_REQUEST })

    const config = {
      headers: {
        'x-app-id': xAppId,
      },
    }

    const response = await axios.post(orderUrl, { userId }, config)
    console.log(response.data.orders)
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: response.data.orders,
    })
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
