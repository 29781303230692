import React from 'react'
import { Container, Row } from 'react-bootstrap'
import styles from './BeautyLine.module.css'

const BeautyLine = () => {
  return (
    <Container className={styles.container}>
      <Row>
        <div className={styles.parent_div}></div>
        <div className={styles.child_div}></div>
      </Row>
    </Container>
  )
}

export default BeautyLine
