export const baseUrl = 'https://vpnpremium.net'
export const paymentUrl = `${baseUrl}/api/payment`
export const packageUrl = `${baseUrl}/api/packages`
export const orderUrl = `${baseUrl}/api/user/order`
export const loginUrl = `${baseUrl}/api/users/login`
export const forgotPasswordUrl = `${baseUrl}/api/users/forgot-password`
export const signupURL = `${baseUrl}/api/users/signup`
export const getLoggedInUrl = `${baseUrl}/api/users/getloggedin`
export const terminateUrl = `${baseUrl}/api/users/signcheck`
export const currentTimeUrl = `${baseUrl}/api/users/current-timestamp`
export const profileUrl = `${baseUrl}/api/users`
export const profileUpdateUrl = `${baseUrl}/api/users/profile`
export const ipLocationUrl = 'https://ipapi.co/json/'

export const footerBgUrl = `${baseUrl}/images/footer-bg.png`
export const pricingBgUrl = `${baseUrl}/images/pricing-bg.png`
export const aboutBgUrl = `${baseUrl}/images/about-bg.png`
export const loginImageUrl = `${baseUrl}/images/signin-vector.png`
export const signupImageUrl = `${baseUrl}/images/signup-vector.png`
export const forgotPasswordImageUrl = `${baseUrl}/images/forgot-password-vector.png`
export const resetPasswordImageUrl = `${baseUrl}/images/reset-password-vector.png`
export const circleLogoUrl = `${baseUrl}/images/circle-logo.png`
export const providerUrl = `${baseUrl}/images/provider.png`
export const aboutIcon1Url = `${baseUrl}/images/about_icon_01.svg`
export const aboutIcon2Url = `${baseUrl}/images/about_icon_02.svg`
export const serviceIcon1Url = `${baseUrl}/images/services_icon1.png`
export const serviceIcon2Url = `${baseUrl}/images/services_icon2.png`
export const serviceIcon3Url = `${baseUrl}/images/services_icon3.png`
export const serviceIcon4Url = `${baseUrl}/images/services_icon4.png`
export const serviceIcon5Url = `${baseUrl}/images/services_icon5.png`
export const serviceIcon6Url = `${baseUrl}/images/services_icon6.png`
export const contactUsUrl = `${baseUrl}/images/contact-us.png`
export const appUrl = `${baseUrl}/images/app.png`
export const playStore = `${baseUrl}/images/play-store.png`
export const telegramImageUrl = `${baseUrl}/images/telegram.png`
export const whatsappImageUrl = `${baseUrl}/images/whatsapp.png`
export const emailImageUrl = `${baseUrl}/images/email.png`
export const counterBgImageUrl = `${baseUrl}/images/counter-bg.png`
export const managementImageUrl = `${baseUrl}/images/management.png`
export const networkImageUrl = `${baseUrl}/images/network.png`
export const satisfactionImageUrl = `${baseUrl}/images/satisfaction.png`
export const downloadsImageUrl = `${baseUrl}/images/downloads.png`
export const aboutusBanner = `${baseUrl}/images/aboutus-banner.png`
export const sessionUrl = `${baseUrl}/images/session.png`

export const xAppId = 'n7QpXuKJmV6bHrLgDx9zYcE2F'
export const nowPaymentsApiKey = 'A1706YY-3QWMNR3-GDKWRJ6-7CAWHRC'
