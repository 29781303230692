import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col, FloatingLabel, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { profile, updateProfile } from '../actions/userActions'
import { USER_PROFILE_UPDATE_RESET } from '../constants/userConstants'
import { listOrders } from '../actions/orderActions'
import Header from '../components/Header'
import Footer from '../components/Footer'

const ProfileScreen = ({ location }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading, error, user } = useSelector((state) => state.userProfile)

  const {
    loading: loadingOrders,
    error: errorOrders,
    orders,
  } = useSelector((state) => state.orderList)

  const { userInfo } = useSelector((state) => state.userLogin)

  const { success } = useSelector((state) => state.userProfileUpdate)

  useEffect(() => {
    if (!userInfo) {
      navigate('/login')
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_PROFILE_UPDATE_RESET })
        dispatch(profile('profile'))
        dispatch(listOrders(userInfo._id))
      } else {
        setName(user.name)
        setEmail(user.email)
      }
    }
  }, [navigate, userInfo, user, success, dispatch])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      dispatch(updateProfile({ id: user._id, name, email, password }))
    }
  }

  const formatDate = (timestamp) => {
    const date = new Date(timestamp)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  return (
    <>
      <Header />
      <Row className="p-5 m-5">
        <Col md={3}>
          <h2>Profile</h2>
          {message && <Message varient="danger">{message}</Message>}
          {error && <Message varient="danger">{error}</Message>}
          {success && (
            <Message varient="success">Profile Updated Successfully!</Message>
          )}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <FloatingLabel controlId="name" label="Name" className="mb-4">
                <Form.Control
                  type="name"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </FloatingLabel>
            </Form.Group>

            <Form.Group controlId="email">
              <FloatingLabel
                controlId="email"
                label="Email Address"
                className="mb-4"
              >
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </FloatingLabel>
            </Form.Group>

            <Form.Group controlId="password">
              <FloatingLabel
                controlId="password"
                label="Password"
                className="mb-4"
              >
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </FloatingLabel>
            </Form.Group>

            <Form.Group controlId="confirmPassword">
              <FloatingLabel
                controlId="password"
                label="Confirm Password"
                className="mb-4"
              >
                <Form.Control
                  type="password"
                  placeholder="Confirm"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </FloatingLabel>
            </Form.Group>

            <Button
              type="submit"
              size="lg"
              variant="primary"
              style={{
                width: '100%',
                height: '3.5rem',
                marginBottom: '5rem',
              }}
            >
              Update
            </Button>
          </Form>
        </Col>
        <Col md={9}>
          <h2>My Orders</h2>
          {loadingOrders ? (
            <Loader />
          ) : errorOrders ? (
            <Message variant="danger">{errorOrders}</Message>
          ) : orders && orders.length > 0 ? (
            <Table striped bordered hover responsive className="table-md">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th>Order ID</th>
                  <th>Price</th>
                  <th>Paid with</th>
                  <th>Pay Status</th>
                  <th>Purchase Date</th>
                  <th>Expiration</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order.orderDescription}</td>
                    <td>{order.orderId}</td>
                    <td>{order.priceAmount} $</td>
                    <td>{order.payCurrency.toUpperCase()}</td>
                    <td style={{color: 'green'}}>{order.paymentStatus.toUpperCase()}</td>
                    <td>{order.createdAt.split('T')[0]}</td>
                    <td>{formatDate(Number(order.expiresAt))}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <h3>You don't have any orders yet</h3>
          )}
        </Col>
      </Row>
      <Footer />
    </>
  )
}

export default ProfileScreen
