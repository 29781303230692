import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { packageListReducer } from './reducers/packageReducers'
import { orderListReducer } from './reducers/orderReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userProfileReducer,
  userProfileUpdateReducer,
  userForgotPasswordReducer,
} from './reducers/userReducers'
import { paymentReducer } from './reducers/paymentReducers'

const reducer = combineReducers({
  packageList: packageListReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userProfile: userProfileReducer,
  userProfileUpdate: userProfileUpdateReducer,
  userForgotPassword: userForgotPasswordReducer,
  paymentRequest: paymentReducer,
  orderList: orderListReducer
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

console.log(store.getState())

export default store
