import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import PrivacyScreen from './screens/PrivacyScreen'
import CheckoutScreen from './screens/CheckoutScreen'
import LoginScreen from './screens/LoginScreen'
import SignupScreen from './screens/SignupScreen'
import ProfileScreen from './screens/ProfileScreen'
import PaymentSuccessScreen from './screens/PaymentSuccessScreen'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen'
import ResetPasswordScreen from './screens/ResetPasswordScreen'
import PricingScreen from './screens/PricingScreen'
import ContactUsScreen from './screens/ContactUsScreen'
import AboutUsScreen from './screens/AboutUsScreen'
import ScrollToTop from './components/ScrollToTop'
import PartiallyPaidScreen from './screens/PartiallyPaidScreen'
import DownloadScreen from './screens/DownloadScreen'
import ActiveSession from './components/ActiveSession'

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" Component={HomeScreen} exact />
        <Route path="/payment-success" Component={PaymentSuccessScreen} />
        <Route path="/partially-paid" Component={PartiallyPaidScreen} />
        <Route path="/login" Component={LoginScreen} />
        <Route path="/signup" Component={SignupScreen} />
        <Route path="/profile" Component={ProfileScreen} />
        <Route path="/session" Component={ActiveSession} />
        <Route path="/privacy" Component={PrivacyScreen} />
        <Route path="/pricing" Component={PricingScreen} />
        <Route path="/contact-us" Component={ContactUsScreen} />
        <Route path="/about-us" Component={AboutUsScreen} />
        <Route path="/download" Component={DownloadScreen} />
        <Route path="/forgot-password" Component={ForgotPasswordScreen} />
        <Route
          path="/reset-password/:id/:token"
          Component={ResetPasswordScreen}
        />
        {/* if want to make :id optional make it :id? in /checkout/... */}
        <Route path="/checkout/:id" Component={CheckoutScreen} />
      </Routes>
    </Router>
  )
}

export default App
