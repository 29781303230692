import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Styles from './ServiceIntro.module.css'
import { aboutIcon1Url, aboutIcon2Url, providerUrl } from '../constant'
import BeautyLine from './BeautyLine'

const ServiceIntro = () => {
  return (
    <Container fluid className={Styles.container}>
      <div className={Styles.div}>
        <Container>
          <Row>
            <Col className={Styles.col}>
              <Image className={Styles.img} src={providerUrl} />
            </Col>
            <Col className={Styles.second_col}>
              <Row className={Styles.row}>
                <div style={{ margin: 'auto' }}>
                  <BeautyLine />
                  <br />
                </div>

                <h1 className={Styles.h1}>
                  Surf the web anonymously without interruption
                </h1>

                <p className={Styles.p}>
                  We have setup the best and the fastest server all around the
                  world so you can surf the web anonymously and connect where
                  ever you want.
                </p>
              </Row>

              <Row className={Styles.row}>
                <div className={Styles.main_div}>
                  <div className={Styles.icon_div}>
                    <Image src={aboutIcon2Url} />
                  </div>
                  <div>
                    <h3 className={Styles.h3}>Government Tracking</h3>
                    <p className={Styles.p}>
                      We have implemented security measures on our servers to
                      protect you from government surveillance
                    </p>
                  </div>
                </div>
              </Row>
              <Row className={Styles.row}>
                <div className={Styles.main_div}>
                  <div className={Styles.icon_div}>
                    <Image src={aboutIcon1Url} />
                  </div>
                  <div>
                    <h3 className={Styles.h3}>Data Encryption</h3>

                    <p className={Styles.p}>
                      Protect your sensitive data with robust encryption
                      technology, ensuring secure and confidential transmission.
                    </p>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  )
}

export default ServiceIntro
