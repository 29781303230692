import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Styles from './AppIntro.module.css'
import { appUrl, playStore } from '../constant'
import BeautyLine from './BeautyLine'

const AppIntro = () => {
  return (
    <Container fluid className={Styles.container}>
      <div className={Styles.div}>
        <Container>
          <Row>
            <Col className={Styles.col} xs={12} md={6}>
              <div className={Styles.imageContainer}>
                <Image className={Styles.img} src={appUrl} fluid />
              </div>
            </Col>
            <Col className={Styles.col} xs={12} md={6}>
              <Row className={Styles.row}>
                <div style={{ margin: 'auto' }}>
                  <BeautyLine />
                  <br />
                </div>

                <h1 className={Styles.h1}>
                  Built App That Everyone Love , Get Your Application
                </h1>

                <p className={Styles.p}>
                  We have developed our android application and now you can
                  download it from google play store in link below.
                </p>
              </Row>

              <Row className={Styles.row}>
                <div className={Styles.main_div}>
                  <a href="https://googleplay.com">
                    <Image src={playStore} style={{paddingBottom: '2rem'}}/>
                  </a>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  )
}

export default AppIntro
