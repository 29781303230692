import React from 'react'
import { Container, Row } from 'react-bootstrap'
import styles from './Divider.module.css'

const Divider = () => {
  return (
    <Container>
      <Row>
        <div className={styles.divider}></div>
      </Row>
    </Container>
  )
}

export default Divider
