import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Styles from './Counter.module.css'
import {
  downloadsImageUrl,
  managementImageUrl,
  networkImageUrl,
  satisfactionImageUrl,
} from '../constant'
import CounterCore from './CounterCore'

const Counter = () => {
  return (
    <Container fluid className={Styles.container}>
      <div className={Styles.div}>
        <Container>
          <Row className={Styles.row}>
            <Col className={Styles.col}>
              <Image src={managementImageUrl} />
              <CounterCore end={1200} />
              <p className={Styles.p}>Support Team</p>
            </Col>
            <Col className={Styles.col}>
              <Image src={networkImageUrl} />
              <CounterCore end={1469} />
              <p className={Styles.p}>Trusted Partner</p>
            </Col>
            <Col className={Styles.col}>
              <Image src={satisfactionImageUrl} />
              <CounterCore end={3696} />
              <p className={Styles.p}>Client Satisfaction</p>
            </Col>
            <Col className={Styles.col}>
              <Image src={downloadsImageUrl} />
              <CounterCore end={9646} />
              <p className={Styles.p}>App Download</p>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  )
}

export default Counter