import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row, Col, Image, FloatingLabel } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { forgotPasswordStateReset } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import Styles from './LoginScreen.module.css'
import {
  baseUrl,
  circleLogoUrl,
  currentTimeUrl,
  resetPasswordImageUrl,
  xAppId,
} from '../constant'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

const ResetPasswordScreen = ({ location }) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isTokenExpired, setIsTokenExpired] = useState(false)
  const [message, setMessage] = useState(null)
  const { id, token } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp; // Expiration time in seconds
    const checkTokenExpiration = async () => {
      try {
        const response = await axios.get(currentTimeUrl, {
          headers: { 'x-app-id': xAppId },
        });
        const currentTimestamp = response.data.timeStamp;
        if (currentTimestamp >= expirationTime) {
          setIsTokenExpired(true);
        } else {
          return;
        }
        console.log(currentTimestamp);
        console.log(expirationTime);
      } catch (error) {
        console.log(error);
      }
    };
  
    checkTokenExpiration();
  }, [token]);

  const { loading, error, success } = useSelector(
    (state) => state.userForgotPassword
  )

  const redirect = location?.search ? location.search.split('=')[1] : '/'

  const handleRedirectHome = () => {
    navigate(redirect)
  }

  useEffect(() => {
    if (success) {
      navigate('/login')
      setTimeout(() => {
        dispatch(forgotPasswordStateReset())
      }, 5 * 60 * 1000)
    }
  }, [navigate, success, dispatch])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      axios
        .post(`${baseUrl}/api/users/reset-password/${id}/${token}`, {
          password,
        })
        .then((res) => {
          navigate('/login')
        })
        .catch((error) => console.log(error))
    }
  }

  if (isTokenExpired) {
    return (
      <>
        <p>The Reset Password Link Has Expired</p>
      </>
    )
  } else {
    return (
      <div style={{ width: '99%' }}>
        <Row lg={2} md={1} xs={1} sm={1}>
          <Col className={Styles.col}>
            <i
              style={{
                position: 'absolute',
                top: '4rem',
                left: '3rem',
                color: 'white',
              }}
              onClick={handleRedirectHome}
              class="fa-solid fa-house fa-2xl"
            ></i>

            <Image
              src={resetPasswordImageUrl}
              style={{ width: '80%', height: 'auto' }}
            ></Image>
          </Col>

          <Col className="d-flex justify-content-center align-items-center">
            <FormContainer>
              <Image
                className="pt-5 m-auto d-flex justify-content-center align-items-center"
                src={circleLogoUrl}
                style={{ width: '25%' }}
              ></Image>
              <h1
                style={{
                  fontFamily: 'Nunito Sans',
                  marginTop: '6rem',
                  marginBottom: '2rem',
                }}
                className="d-flex justify-content-center align-items-center"
              >
                Reset Password
              </h1>

              <br />
              {message && <Message varient="danger">{message}</Message>}
              {error && <Message varient="danger">{error}</Message>}
              {loading && <Loader />}
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="password">
                  <FloatingLabel
                    controlId="password"
                    label="New Password"
                    className="mb-4"
                  >
                    <Form.Control
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="confirmPassword">
                  <FloatingLabel
                    controlId="password"
                    label="Confirm Password"
                    className="mb-4"
                  >
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    ></Form.Control>
                  </FloatingLabel>
                </Form.Group>

                <Button
                  type="submit"
                  size="lg"
                  variant="primary"
                  style={{
                    width: '100%',
                    height: '3.5rem',
                    marginBottom: '10rem',
                  }}
                >
                  Change
                </Button>
              </Form>
            </FormContainer>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ResetPasswordScreen
