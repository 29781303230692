import axios from 'axios'
import {
  PAYMENT_REQUEST,
  PAYMENT_REQUEST_SUCCESS,
  PAYMENT_REQUEST_FAIL,
  RESET_PAY_INFO,
} from '../constants/paymentConstants'
import { paymentUrl } from '../constant'

export const proceedPayment = (pkg, currency) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const response = await axios.post(paymentUrl, {
      package: pkg,
      currency: currency,
      orderId: userInfo._id,
    })

    dispatch({
      type: PAYMENT_REQUEST_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: PAYMENT_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const resetPayInfo = () => ({
  type: RESET_PAY_INFO,
})
