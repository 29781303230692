import React from 'react'
import Packages from '../components/Packages'
import Header from '../components/Header'
import Footer from '../components/Footer'

const PricingScreen = () => {
  return (
    <div>
        <Header />
        <Packages />
        <Footer />
    </div>
  )
}

export default PricingScreen