import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Styles from './Services.module.css'
import BeautyLine from './BeautyLine'
import {
  serviceIcon1Url,
  serviceIcon2Url,
  serviceIcon3Url,
  serviceIcon4Url,
  serviceIcon5Url,
  serviceIcon6Url,
} from '../constant'

const Services = () => {
  return (
    <Container fluid>
      <div className={Styles.div}>
        <Row>
          <BeautyLine />
          <br />
          <br />
          <h2 className={Styles.h2}>Valuable Service</h2>

          <p className={Styles.p}>
          Experience the security , privacy and speed of <strong>VPN Premium</strong> in your business. 
          </p>
        </Row>
        <Container>
          <Row className={Styles.row}>
            <Col xxl={4} xl={4} lg={4}>
              <div className={Styles.icon_div}>
                <Image src={serviceIcon2Url} />
              </div>
              <h3 className={Styles.h3}>Premium Services</h3>
              <p className={Styles.p_col}>
                All our services are premium and we don't provide unsafe free
                service.
              </p>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <div className={Styles.icon_div}>
                <Image src={serviceIcon4Url} />
              </div>
              <h3 className={Styles.h3}>No Traffic Logs</h3>
              <p className={Styles.p_col}>
                We don't collect users traffic logs due to our policy, privacy
                matters.
              </p>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <div className={Styles.icon_div}>
                <Image src={serviceIcon5Url} />
              </div>
              <h3 className={Styles.h3}>Instant Setups</h3>
              <p className={Styles.p_col}>
                Purchase your preferred plan, download our application, and use
                it. see the prices in pricing section.
              </p>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <div className={Styles.icon_div}>
                <Image src={serviceIcon6Url} />
              </div>
              <h3 className={Styles.h3}>Online Privacy</h3>
              <p className={Styles.p_col}>
                Private Internet is a leading provider of security and safety.
              </p>
            </Col>

            <Col xxl={4} xl={4} lg={4}>
              <div className={Styles.icon_div}>
                <Image src={serviceIcon3Url} />
              </div>
              <h3 className={Styles.h3}>Online Security</h3>
              <p className={Styles.p_col}>
                We prioritize security in our online services and offer
                top-notch solutions through our Private Internet services.
              </p>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <div className={Styles.icon_div}>
                <Image src={serviceIcon1Url} />
              </div>
              <h3 className={Styles.h3}>Online Encryption</h3>
              <p className={Styles.p_col}>
                Our advanced encryption ensures utmost data security and
                prevents any leakage of sensitive information during transfer.{' '}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  )
}

export default Services
