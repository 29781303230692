import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col, Image, FloatingLabel } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  forgotPassword,
  forgotPasswordStateReset,
} from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import Styles from './LoginScreen.module.css'
import { circleLogoUrl, forgotPasswordImageUrl } from '../constant'

const ForgotPasswrodScreen = ({ location }) => {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading, error, success } = useSelector(
    (state) => state.userForgotPassword
  )

  const redirect = location?.search ? location.search.split('=')[1] : '/'

  const handleRedirectHome = () => {
    navigate(redirect)
  }

  useEffect(() => {
    if (success) {
      navigate('/login')
      setTimeout(() => {
        dispatch(forgotPasswordStateReset())
      }, 5 * 60 * 1000)
    }
  }, [navigate, success, dispatch])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(forgotPassword(email))
  }

  return (
    <div style={{ width: '99%' }}>
      <Row lg={2} md={1} xs={1} sm={1}>
        <Col className={Styles.col}>
          <i
            style={{
              position: 'absolute',
              top: '4rem',
              left: '3rem',
              color: 'white',
            }}
            onClick={handleRedirectHome}
            class="fa-solid fa-house fa-2xl"
          ></i>

          <Image
            src={forgotPasswordImageUrl}
            style={{ width: '80%', height: 'auto' }}
          ></Image>
        </Col>

        <Col className="d-flex justify-content-center align-items-center">
          <FormContainer>
            <Image
              className="pt-5 m-auto d-flex justify-content-center align-items-center"
              src={circleLogoUrl}
              style={{ width: '25%' }}
            ></Image>
            <h1
              style={{
                fontFamily: 'Nunito Sans',
                marginTop: '6rem',
                marginBottom: '2rem',
              }}
              className="d-flex justify-content-center align-items-center"
            >
              Forgot Password
            </h1>

            <p style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Rubik', fontWeight: 'normal'}}>
              Don't Worry !
              <br /> Enter your email and wait until we send you a reset
              password link
            </p>

            <br />
            {error && <Message varient="danger">{error}</Message>}
            {loading && <Loader />}
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="email">
                <FloatingLabel
                  controlId="email"
                  label="Email address"
                  className="mb-4"
                >
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>

              <Button
                type="submit"
                size="lg"
                variant="primary"
                style={{
                  width: '100%',
                  height: '3.5rem',
                  marginBottom: '10rem',
                }}
              >
                Send
              </Button>
            </Form>

            <Row>
              <Col
                style={{ fontFamily: 'Nunito Sans', fontWeight: 'bold' }}
                className="d-flex justify-content-center"
              >
                <span className="pb-5" style={{ marginRight: '1rem' }}>
                  Don't Have An Account Yet?
                </span>
                <Link
                  style={{ color: '#0d6efd', textDecoration: 'none' }}
                  to={redirect ? `/signup?redirect=${redirect}` : '/signup'}
                >
                  Register
                </Link>
              </Col>
            </Row>
          </FormContainer>
        </Col>
      </Row>
    </div>
  )
}

export default ForgotPasswrodScreen
