import React from 'react'
import { Spinner } from 'react-bootstrap'
import Styles from './Loader.module.css'

const Loader = () => {
  return (
    <Spinner animation="border" role="status" className={Styles.spinner}>
      <span className="sr-only">Loading ... </span>
    </Spinner>
  )
}

export default Loader
