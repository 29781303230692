import React from 'react'
import { Container, Card, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const PaymentSuccessScreen = () => {
  const navigate = useNavigate()

  const handleButtonClick = () => {
    navigate('/')
  }

  return (
    <Container
      fluid
      style={{ height: '100vh' }}
      className="d-flex align-items-center justify-content-center"
    >
      <Card
        className="mt-5 mb-5"
        style={{
          width: '35rem',
          height: '50rem',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          borderRadius: '1rem',
        }}
      >
        <Card.Body className="d-flex flex-column  align-items-center">
          <Card.Title
            style={{ fontSize: '2.5rem', color: '#2ecc71', fontWeight: 'bold', fontFamily: 'Rubik' }}
            className="mt-5 mb-5"
          >
            Success
          </Card.Title>

          <i
            className="fa-solid fa-circle-check fa-beat"
            style={{ color: '#2ecc71', scale: '8', margin: '8rem' }}
          ></i>

          <Card.Text style={{marginTop: '8rem', fontFamily: 'Rubik'}}>Payment was Successful</Card.Text>
          <Card.Text style={{fontFamily: 'Rubik'}}>Now you can access our services</Card.Text>
          <Button
            style={{
              backgroundColor: '#2ecc71',
              borderColor: '#2ecc71',
              width: '20rem',
              height: '3rem',
              fontFamily: 'Rubik',
            }}
            onClick={handleButtonClick}
          >
            Go Home
          </Button>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default PaymentSuccessScreen
