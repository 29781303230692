import React, { useState } from 'react'
import Styles from './AboutUsScreen.module.css'
import { Image } from 'react-bootstrap'
import { aboutusBanner } from '../constant'
import Header from '../components/Header'
import Footer from '../components/Footer'

const AboutUsScreen = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const handleImageLoad = () => {
    setIsLoaded(true)
  }
  return (
    <>
      <Header />

      <div className={Styles.imageContainer}>
        <Image
          src={aboutusBanner}
          alt="About Us Banner"
          onLoad={handleImageLoad}
          fluid
        />
      </div>
      {/* <div className={Styles.overlay}>
        <p className={Styles.text}>About Us</p>
      </div> */}

      <div className={Styles.about_us_container}>
        <h2 className={Styles.h2}>About Us</h2>

        <div className={Styles.about_us_content}>
          <p className={Styles.p}>
            At <strong>VPN Premium</strong>, we are committed to providing you
            with a reliable and secure virtual private network (VPN) solution.
            Our mission is to protect your online privacy and security, allowing
            you to browse the internet with confidence and freedom.
          </p>

          <h3 className={Styles.h3}>Who We Are</h3>
          <p className={Styles.p}>
            We are a team of cybersecurity experts with years of experience in
            the industry. Our passion for online privacy and dedication to user
            security has driven us to develop a VPN service that prioritizes
            your needs and safeguards your digital presence.
          </p>

          <h3 className={Styles.h3}>Our Vision</h3>
          <p className={Styles.p}>
            Our vision is to empower individuals and businesses to reclaim their
            online privacy. We believe that everyone should have the right to
            browse the internet without the fear of being tracked or monitored.
            By offering a robust VPN service, we aim to create a safer and more
            private online environment for our users.
          </p>

          <h3 className={Styles.h3}>What Sets Us Apart</h3>
          <ul className={Styles.ul}>
            <li className={Styles.li}>
              Advanced Encryption: We utilize advanced encryption algorithms to
              secure your internet traffic and protect your sensitive data from
              unauthorized access.
            </li>
            <li className={Styles.li}>
              Global Server Network: Our extensive server network spans across
              multiple countries, enabling you to connect to VPN servers from
              different locations and access geo-restricted content with ease.
            </li>
            <li className={Styles.li}>
              Zero-Logging Policy: We have a strict zero-logging policy,
              ensuring that we do not collect or store any of your browsing
              activities or personal information.
            </li>
            <li className={Styles.li}>
              Fast and Reliable Connections: Our high-speed servers and
              optimized network infrastructure ensure blazing-fast and reliable
              VPN connections for seamless browsing and streaming.
            </li>
            <li className={Styles.li}>
              User-Friendly Experience: We have designed our VPN service with a
              user-friendly interface that makes it easy for anyone, regardless
              of technical expertise, to connect to our VPN and enjoy enhanced
              privacy.
            </li>
          </ul>

          <h3 className={Styles.h3}>Our Commitment to Privacy</h3>
          <p className={Styles.p}>
            We take your privacy seriously. We adhere to strict privacy
            standards and industry best practices to protect your personal
            information. Your online activities are your own, and we will never
            compromise your privacy by selling or sharing your data with third
            parties.
          </p>

          <h3 className={Styles.h3}>Contact Us</h3>
          <p className={Styles.p}>
            We value your feedback and are here to assist you. If you have any
            questions, concerns, or suggestions, please don't hesitate to reach
            out to our friendly customer support team. You can contact us
            through our website, and we will be more than happy to assist you.
          </p>

          <p className={Styles.p}>
            Thank you for choosing <strong>VPN Premium</strong>. We are excited
            to serve you and help you reclaim your online privacy and security.
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutUsScreen
