export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL'
export const USER_PROFILE_RESET = 'USER_PROFILE_RESET'

export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST'
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS'
export const USER_PROFILE_UPDATE_FAIL = 'USER_PROFILE_UPDATE_FAIL'
export const USER_PROFILE_UPDATE_RESET = 'USER_PROFILE_UPDATE_RESET'

export const USER_PASSWORD_RECOVER_REQUEST = 'USER_PASSWORD_RECOVER_REQUEST'
export const USER_PASSWORD_RECOVER_SUCCESS = 'USER_PASSWORD_RECOVER_SUCCESS'
export const USER_PASSWORD_RECOVER_FAIL = 'USER_PASSWORD_RECOVER_FAIL'
export const USER_PASSWORD_RECOVER_STATE_RESET = 'USER_PASSWORD_RECOVER_STATE_RESET'
