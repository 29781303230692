import React from 'react'
import ShieldBanner from '../components/ShieldBanner'
import Packages from '../components/Packages'
import ServiceIntro from '../components/ServiceIntro'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Services from '../components/Services'
import AppIntro from '../components/AppIntro'
import Counter from '../components/Counter'

const HomeScreen = () => {
  return (
    <>
      <main>
        <Header />
        <ShieldBanner />
        <Services />
        <ServiceIntro />
        <Packages />
        <Counter />
        <AppIntro />
        <Footer />
      </main>
    </>
  )
}

export default HomeScreen
