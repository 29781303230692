import React from 'react'
import { Container, Card, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const PartiallyPaidScreen = () => {
  const navigate = useNavigate()

  const handleButtonClick = () => {
    navigate('/contact-us')
  }

  return (
    <Container
      fluid
      style={{ height: '100vh' }}
      className="d-flex align-items-center justify-content-center"
    >
      <Card
        className="mt-5 mb-5"
        style={{
          width: '35rem',
          height: '50rem',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          borderRadius: '1rem',
        }}
      >
        <Card.Body className="d-flex flex-column  align-items-center">
          <Card.Title
            style={{
              fontSize: '2.5rem',
              color: '#ff8000',
              fontWeight: 'bold',
              fontFamily: 'Rubik',
            }}
            className="mt-5 mb-5"
          >
            Partially Paid
          </Card.Title>

          <i
            className="fa-solid fa-circle-exclamation fa-beat"
            style={{ color: '#ff8000', scale: '8', margin: '8rem' }}
          ></i>

          <Card.Text style={{ marginTop: '8rem', fontFamily: 'Rubik' }}>
            Payment Not Completed
          </Card.Text>
          <Card.Text
            style={{
              fontFamily: 'Rubik',
              textAlign: 'center',
              padding: '1rem',
            }}
          >
            You paid less than expected, contact the support team to continue
            the process
          </Card.Text>
          <Button
            style={{
              backgroundColor: '#ff8000',
              borderColor: '#ff8000',
              width: '20rem',
              height: '3rem',
              fontFamily: 'Rubik',
            }}
            onClick={handleButtonClick}
          >
            Contact support
          </Button>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default PartiallyPaidScreen
