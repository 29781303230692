import {
  PAYMENT_REQUEST,
  PAYMENT_REQUEST_FAIL,
  PAYMENT_REQUEST_SUCCESS,
  RESET_PAY_INFO
} from '../constants/paymentConstants'

export const paymentReducer = (state = { payInfo: {} }, action) => {
  switch (action.type) {
    case PAYMENT_REQUEST:
      return { loading: true, payInfo: {} }
    case PAYMENT_REQUEST_SUCCESS:
      return { loading: false, payInfo: action.payload }
      case RESET_PAY_INFO:
      return { payInfo: {} }
    case PAYMENT_REQUEST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
