import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Container, NavDropdown, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import StatusBar from './StatusBar'
import { logout } from '../actions/userActions'
import Styles from './Header.module.css'
import { circleLogoUrl, currentTimeUrl, xAppId } from '../constant'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

const Header = () => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.userLogin)

  useEffect(() => {
    console.log(userInfo)
    if (userInfo) {
      const decodedToken = jwtDecode(userInfo.token)
      const expirationTime = decodedToken.exp
      const checkTokenExpiration = async () => {
        try {
          const response = await axios.get(currentTimeUrl, {
            headers: { 'x-app-id': xAppId },
          })
          const currentTimestamp = response.data.timeStamp
          if (currentTimestamp >= expirationTime) {
            dispatch(logout())
            console.log('logout dispatched')
          } else {
            return
          }
          console.log(currentTimestamp)
          console.log(expirationTime)
        } catch (error) {
          console.log(error)
        }
      }
      checkTokenExpiration()
    }
  }, [userInfo, dispatch])

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <header>
      <StatusBar />
      <Navbar className={Styles.navbar} expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand className={Styles.navbar_brand}>
              <Image src={circleLogoUrl} className={Styles.logo} /> VPN Premium
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <LinkContainer to="/">
                <Nav.Link className={Styles.link}>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/pricing">
                <Nav.Link className={Styles.link}>Pricing</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/download">
                <Nav.Link className={Styles.link}>Download</Nav.Link>
              </LinkContainer>

              {userInfo ? (
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item className={Styles.dropDown}>
                      <i class="fa-solid fa-user"></i> Profile
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item
                    className={Styles.dropDown}
                    onClick={logoutHandler}
                  >
                    <i class="fa-solid fa-right-from-bracket"></i> Logout
                  </NavDropdown.Item>
                  <LinkContainer to="/session">
                    <NavDropdown.Item className={Styles.dropDown_session}>
                      Active Session
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link className={Styles.link}>
                    <i className="fa-solid fa-user"></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
