import React, { useState } from 'react'
import { Image, Card, Col, Row, Container, Button } from 'react-bootstrap'
import {
  aboutusBanner,
  emailImageUrl,
  telegramImageUrl,
  whatsappImageUrl,
} from '../constant'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Styles from './ContactUsScreen.module.css'

const ContactUsScreen = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoadedTelegram, setIsLoadedTelegram] = useState(false)
  const [isLoadedWhatsApp, setIsLoadedWhatsApp] = useState(false)
  const [isLoadedEmail, setIsLoadedEmail] = useState(false)

  const handleImageLoad = () => {
    setIsLoaded(true)
  }

  const handleImageLoadTelegram = () => {
    setIsLoadedTelegram(true)
  }

  const handleImageLoadWhatsApp = () => {
    setIsLoadedWhatsApp(true)
  }

  const handleImageLoadEmail = () => {
    setIsLoadedEmail(true)
  }

  const telegramClickHandler = () => {
    window.open('https://telegram.com', '_blank')
  }

  const whatsAppClickHandler = () => {
    window.open('https://whatsapp.com', '_blank')
  }

  return (
    <>
      <Header />
      <div className={Styles.imageContainer}>
        <Image
          src={aboutusBanner}
          alt="Contact Us Banner"
          onLoad={handleImageLoad}
          fluid
        />
      </div>

      {/* <div className={Styles.overlay}>
        <p className={Styles.image_text}>Contact Us</p>
      </div> */}
      <Container>
        <div className={Styles.text_div}>
          <h3>Get in touch with us</h3>
          <p>
            If you have any questions or inquiries, feel free to reach out to us
            through any of the following methods:
          </p>
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={4}>
            <Card
              style={{ backgroundColor: '#ceefff' }}
              className={Styles.card}
            >
              <Card.Img
                variant="top"
                src={telegramImageUrl}
                onLoad={handleImageLoadTelegram}
              />
              <Card.Body className={Styles.text}>
                <Card.Title>Chat in Telegram</Card.Title>
                <Card.Text>
                  You can chat with us on Telegram by clicking the following
                  button.
                </Card.Text>
                <Button onClick={telegramClickHandler}>Telegram</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4}>
            {' '}
            <Card
              style={{ backgroundColor: '#99ff6d' }}
              className={Styles.card}
            >
              <Card.Img
                variant="top"
                src={whatsappImageUrl}
                onLoad={handleImageLoadWhatsApp}
              />
              <Card.Body className={Styles.text}>
                <Card.Title>Chat in WhatsApp</Card.Title>
                <Card.Text>
                  You can chat with us on WhatsApp by clicking the following
                  button.
                </Card.Text>
                <Button onClick={whatsAppClickHandler}>WhatsApp</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4}>
            {' '}
            <Card
              style={{ backgroundColor: '#ffe36d' }}
              className={Styles.card}
            >
              <Card.Img
                variant="top"
                src={emailImageUrl}
                onLoad={handleImageLoadEmail}
              />
              <Card.Body className={Styles.text}>
                <Card.Title>Email</Card.Title>
                <Card.Text>
                  You can also reach us via email. Please send your inquiries
                  to:
                  <a
                    className={Styles.a}
                    href="mailto:vpnpremium.net@gmail.com"
                  >
                    {' '}
                    vpnpremium.net@gmail.com
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default ContactUsScreen
