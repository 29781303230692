import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AppIntro from '../components/AppIntro'

const DownloadScreen = () => {
  return (
    <>
      <Header />
      <AppIntro />
      <Footer />
    </>
  )
}

export default DownloadScreen
